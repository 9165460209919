import React, { useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export default function Footer() {
  const [isDivVisible, setIsDivVisible] = useState(false);

  const toggleDiv = () => {
    setIsDivVisible(!isDivVisible);
  };

  return (
    <div className="footertext">
      {isDivVisible && (
        <div className="privacy">
          <span onClick={toggleDiv}>
            <CloseRoundedIcon style={{ color: "white" }} />
          </span>
          <div className="privacycontent">
            <h2>Adresse</h2>
            <p>
              Optimus GmbH
              <br /> Preysingstraße 56, <br /> 85283 Wolnzach, Deutschland{" "}
              <br />
            </p>
            <h2>Amtsgericht Ingolstadt</h2>
            <p>HRB 11801</p>
            <p>Geschäftsführer:</p>
            <p>Besnik Xhafaj</p>
            <h2>Kontakt</h2>
            <p>Telefon: +49 8442 9577544 </p>
            <p>Email: info@optimus-deep.com</p>
            <h2>1. Bedingungen</h2>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </p>
            <h2>2. Nutzungslizenz</h2>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </p>
            <h2>3. Haftungsausschluss</h2>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </p>
            <h2>4. Einschränkungen</h2>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </p>
            <h2>5. Änderungen und Errata</h2>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </p>
            <h2>6. Links</h2>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </p>
            <h2>7. Änderungen der Nutzungsbedingungen der Website</h2>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam.
            </p>
            <h2>8. Datenschutz</h2>
            <p>Bitte lesen Sie unsere Datenschutzrichtlinie.</p>
            <h2>9. Anwendbares Recht</h2>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </p>
            <h2>10. Zustimmung</h2>
            <p>
              Durch die Nutzung unserer Website stimmen Sie hiermit unserer
              Datenschutzrichtlinie zu und akzeptieren deren Bedingungen.
            </p>
            <h2>11. Informationen, die wir sammeln</h2>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </p>
          </div>
        </div>
      )}

      <div className="footertextblock">
        <h2>Optimus GmbH</h2>
        <p>
          Die Optimus GmbH ist ein führendes Unternehmen im Bereich Tiefbau und
          Infrastruktur mit langjähriger Erfahrung in der Umsetzung komplexer
          Projekte. Unser Team aus Fachleuten arbeitet stets mit höchster
          Präzision und Verantwortung, um maßgeschneiderte Lösungen zu
          entwickeln, die den Anforderungen der heutigen Zeit gerecht werden.
          Wir bieten ein breites Leistungsspektrum, das von Glasfaser- und
          Stromnetzausbau bis hin zu innovativen Lösungen im Kanalbau reicht.
          Durch unsere Expertise und unser Engagement sorgen wir dafür, dass
          jedes Projekt effizient und nachhaltig realisiert wird. Vertrauen Sie
          auf die Kompetenz der Optimus GmbH - Ihrem Partner für zukunftsfähige
          Infrastrukturen.
        </p>
      </div>
      <div className="footertextblock">
        <h2>Seitenübersicht</h2>
        <a href="#ueberuns">Über uns</a>
        <a href="#leistungen">Leistungen</a>
        <a href="#mission">Unsere Mission</a>
        <span onClick={toggleDiv} style={{ cursor: "pointer" }}>
          Datenschutz
        </span>
        <span onClick={toggleDiv} style={{ cursor: "pointer" }}>
          Impressum
        </span>
      </div>
      <div className="footertextblock">
        <h2>Kontakt</h2>
        <span> Preysingstraße 56, </span>
        <span> 85283 Wolnzach, Deutschland</span>
        <span> Email: info@optimus-deep.com</span>
        <span> Telefon: +49 8442 9577544 </span>
        <span> © Copyright Optimus GmbH 2025 </span>
      </div>
    </div>
  );
}
