import React from "react";
import { useInView } from "react-intersection-observer";

const FadingElement = ({ children }) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger the animation only once
    threshold: 0.5, // Trigger when 50% of the element is in view
  });

  return (
    <div ref={ref} className={`fade-in-element ${inView ? "visible" : ""}`}>
      {children}
    </div>
  );
};

export default FadingElement;
